import { useState, useEffect } from 'react';
import GlobalStyle from '../../style/Global';
import {
    withStyles,
    Grid,
    Typography,
    TextField,
    Button,
    SnackbarContent,
    CircularProgress,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';

import {
    DataGrid
} from '@material-ui/data-grid';

import BackIcon from '@material-ui/icons/KeyboardBackspace';

import _ from 'underscore';

function Settings ({
    organization,
    DB,
    CDN,
    currentUser,
    setDashboardScene,
    classes
}) {

    const [displayTitle, setDisplayTitle] = useState("");
    const [updateFormType, setUpdateFormType] = useState("");
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [emailAddress, setEmailAddress] = useState("");
    const [emailConfirmation, setEmailConfirmation] = useState("");
    const [securePassword, setSecurePassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [image, setImage] = useState(null);
    const [description, setDescription] = useState(organization.description);
    const [website, setWebsite] = useState(organization.website);
    const [phoneNumber, setPhoneNumber] = useState(organization.phoneNumber);
    const [confirmPhone, setConfirmPhone] = useState("");
    const [zipCode, setZipCode] = useState(organization.zipCode);
    const [facebookProfile, setFacebookProfile] = useState(_.find(organization.socialMedia, (socialProfile) => {
        return socialProfile.type === 'facebook';
    }));
    const [instagramProfile, setInstagramProfile] = useState(_.find(organization.socialMedia, (socialProfile) => {
        return socialProfile.type === 'instagram';
    }));
    const [twitterProfile, setTwitterProfile] = useState(_.find(organization.socialMedia, (socialProfile) => {
        return socialProfile.type === 'twitter';
    }));

    const showFeedback = (message, type) => {
        setMessage({
            style: type,
            content: message
        });

        setTimeout(() => {setMessage(null)}, 7000);
    }

    const updateField = () => {
        switch (updateFormType) {
            case 'email':
                if (emailAddress !== emailConfirmation) {
                    return showFeedback('Email mismatch', classes.error);
                } else if (!emailAddress || !emailConfirmation) {
                    return showFeedback('All fields required', classes.error);
                }

                setLoading(true);
                currentUser.updateEmail(emailAddress)
                .then(() => {
                    DB.collection('organizations').doc(currentUser.uid)
                    .update({
                        emailAddress: emailAddress
                    })
                    .then(() => {
                        showFeedback('Email updated successfully!', classes.success);
                        setShowUpdateForm(false);
                        setLoading(false);
                    })
                    .catch((error) => {
                        showFeedback(error.message, classes.error);
                        setLoading(false);
                    })                    
                })
                .catch((error) => {
                    showFeedback(error.message, classes.error);
                    setLoading(false);
                })

                break;
            case 'password':
                if (securePassword !== passwordConfirmation) {
                    return showFeedback('Password mismatch', classes.error);
                } else if (!securePassword || !passwordConfirmation) {
                    return showFeedback('All fields required', classes.error);
                }

                setLoading(true);
                currentUser.updatePassword(securePassword)
                .then(() => {
                    showFeedback('Password updated successfully!', classes.success);
                    setShowUpdateForm(false);
                    setLoading(false);                  
                })
                .catch((error) => {
                    showFeedback(error.message, classes.error);
                    setLoading(false);
                })

                break;
            case 'image':
                if (!image) {
                    return showFeedback('Image required', classes.error);
                }

                setLoading(true);

                const imageRef = CDN.ref().child('organizations/images/'+organization.id);

                imageRef.put(image)
                .then(() => {
                    imageRef.getDownloadURL()
                    .then((url) => {
                        DB.collection('organizations').doc(organization.id)
                        .update({
                            image: url
                        })
                        .then(() => {
                            showFeedback('Image updated successfully!', classes.success);
                            setImage(null);
                            setShowUpdateForm(false);
                            setLoading(false);      
                        })
                        .catch((error) => {
                            showFeedback(error.message, classes.error);
                            setLoading(false);
                        })
                    })
                    .catch((error) => {
                        showFeedback(error.message, classes.error);
                        setLoading(false);
                    })
                })
                .catch((error) => {
                    showFeedback(error.message, classes.error);
                    setLoading(false);
                })

                break;
            case 'description':
                if (!description) {
                    return showFeedback('Description required', classes.error);
                }

                setLoading(true);

                DB.collection('organizations').doc(currentUser.uid)
                .update({
                    description: description
                })
                .then(() => {
                    showFeedback('Description updated successfully!', classes.success);
                    setShowUpdateForm(false);
                    setLoading(false);
                })
                .catch((error) => {
                    showFeedback(error.message, classes.error);
                    setLoading(false);
                })                    

                break;
            case 'website':
                if (!website) {
                    return showFeedback('Website required', classes.error);
                }

                setLoading(true);

                DB.collection('organizations').doc(currentUser.uid)
                .update({
                    website: website
                })
                .then(() => {
                    showFeedback('Website updated successfully!', classes.success);
                    setShowUpdateForm(false);
                    setLoading(false);
                })
                .catch((error) => {
                    showFeedback(error.message, classes.error);
                    setLoading(false);
                })                    

                break;
            case 'phone':
                if (!phoneNumber) {
                    return showFeedback('Phone required', classes.error);
                }

                setLoading(true);

                DB.collection('organizations').doc(currentUser.uid)
                .update({
                    phoneNumber: phoneNumber
                })
                .then(() => {
                    showFeedback('Phone updated successfully!', classes.success);
                    setShowUpdateForm(false);
                    setLoading(false);
                })
                .catch((error) => {
                    showFeedback(error.message, classes.error);
                    setLoading(false);
                })                    

                break;
            case 'zipCode':
                if (!zipCode) {
                    return showFeedback('Zip code required', classes.error);
                }

                setLoading(true);

                DB.collection('organizations').doc(currentUser.uid)
                .update({
                    zipCode: zipCode
                })
                .then(() => {
                    showFeedback('Zip code updated successfully!', classes.success);
                    setShowUpdateForm(false);
                    setLoading(false);
                })
                .catch((error) => {
                    showFeedback(error.message, classes.error);
                    setLoading(false);
                })                    

                break;
            case 'socialMedia':

                if (facebookProfile.url === '' && instagramProfile.url === '' && twitterProfile.url === '') {
                    return showFeedback('At least one profile url required', classes.error);
                }

                setLoading(true);

                DB.collection('organizations').doc(currentUser.uid)
                .update({
                    socialMedia: [facebookProfile, instagramProfile, twitterProfile]
                })
                .then(() => {
                    showFeedback('Social profiles updated successfully!', classes.success);
                    setShowUpdateForm(false);
                    setLoading(false);
                })
                .catch((error) => {
                    showFeedback(error.message, classes.error);
                    setLoading(false);
                })                    

                break;
            default:
                break;
        }
    }

    const managePayoutAccount = async () => {
        setLoading(true);

        try {
            const loginUrl = await fetch('https://us-central1-champs-change-app.cloudfunctions.net/createConnectLoginLink', {
                method: 'POST',
                header: {
                    'Content-Type': 'application/json'
                },
                mode: 'cors',
                body: JSON.stringify({
                    id: organization.private.payoutAccount.id
                })
            });

            const parsedLoginUrl = await loginUrl.text();

            window.open(parsedLoginUrl, '_blank');
            setLoading(false);
        } catch (error) {
            setLoading(false);
            showFeedback(error.message, classes.error);
        }
    }


    return (<Grid item container xs={12}>
        {message && <SnackbarContent
            style={{zIndex: 99999999999}}
            className={message.style}
            message={message.content}
        />}
        <Grid item container xs={12}>
            <Grid item container xs={12} style={{minHeight: '3rem', backgroundColor: '#444', textAlign: 'center'}}>
                {!loading && <Grid item xs={12} style={{margin: '1rem', textAlign: 'left', minHeight: '5rem'}}>
                    <Typography variant="h6" component="h1" style={{fontWeight: '300', fontSize: '2rem', color: '#f5f5f5'}}>Settings</Typography>
                    <Typography onClick={() => setDashboardScene('index')} variant="h6" component="h1" style={{fontFamily: 'helvetica', fontSize: '1rem', cursor: 'pointer', fontWeight: '200', color: '#f5f5f5'}}><BackIcon style={{verticalAlign: 'bottom'}}/> go back</Typography>
                </Grid>}
                {loading && <Grid item xs={12} style={{margin: '1rem', padding: '1rem', textAlign: 'left', minHeight: '5rem'}}>
                    <CircularProgress size={40} style={{color: '#f5f5f5'}}/>
                </Grid>}
            </Grid>
            <Grid xs={12} style={{padding: '2rem'}}>
                {organization.private.payoutsSetup && <div><Button
                    disabled={loading}
                    variant="contained"
                    onClick={managePayoutAccount}
                    style={{fontWeight: '300', fontSize: '1rem', textTransform: 'none', color: 'green', cursor: 'pointer', marginBottom: '1rem'}}
                >
                    Manage Payout Account
                </Button>
                </div>}
                <div>
                    <Button
                        disabled={loading}
                        variant="contained"
                        onClick={() => {setDisplayTitle('Update Email'); setUpdateFormType('email'); setShowUpdateForm(true)}}
                        style={{fontWeight: '300', fontSize: '1rem', textTransform: 'none', color: 'green', cursor: 'pointer', marginBottom: '1rem'}}
                    >
                        Change Email Address
                    </Button>
                </div>
                <div>
                    <Button
                        disabled={loading}
                        variant="contained"
                        onClick={() => {setDisplayTitle('Update Password'); setUpdateFormType('password'); setShowUpdateForm(true)}}
                        style={{fontWeight: '300', fontSize: '1rem', textTransform: 'none', color: 'green', cursor: 'pointer', marginBottom: '1rem'}}
                    >
                        Change Password
                    </Button>
                </div>
                <div>
                    <Button
                        disabled={loading}
                        variant="contained"
                        onClick={() => {setDisplayTitle('Update Image'); setUpdateFormType('image'); setShowUpdateForm(true)}}
                        style={{fontWeight: '300', fontSize: '1rem', textTransform: 'none', color: 'green', cursor: 'pointer', marginBottom: '1rem'}}
                    >
                        Update Image
                    </Button>
                </div>
                <div>
                    <Button
                        disabled={loading}
                        variant="contained"
                        onClick={() => {setDisplayTitle('Update Description'); setUpdateFormType('description'); setShowUpdateForm(true)}}
                        style={{fontWeight: '300', fontSize: '1rem', textTransform: 'none', color: 'green', cursor: 'pointer', marginBottom: '1rem'}}
                    >
                        Update Description
                    </Button>
                </div>
                <div>
                    <Button
                        disabled={loading}
                        variant="contained"
                        onClick={() => {setDisplayTitle('Update Website'); setUpdateFormType('website'); setShowUpdateForm(true)}}
                        style={{fontWeight: '300', fontSize: '1rem', textTransform: 'none', color: 'green', cursor: 'pointer', marginBottom: '1rem'}}
                    >
                        Update Website
                    </Button>
                </div>
                <div>
                    <Button
                        disabled={loading}
                        variant="contained"
                        onClick={() => {setDisplayTitle('Update Phone'); setUpdateFormType('phone'); setShowUpdateForm(true)}}
                        style={{fontWeight: '300', fontSize: '1rem', textTransform: 'none', color: 'green', cursor: 'pointer', marginBottom: '1rem'}}
                    >
                        Update Phone
                    </Button>
                </div>
                <div>
                    <Button
                        disabled={loading}
                        variant="contained"
                        onClick={() => {setDisplayTitle('Update Zip Code'); setUpdateFormType('zipCode'); setShowUpdateForm(true)}}
                        style={{fontWeight: '300', fontSize: '1rem', textTransform: 'none', color: 'green', cursor: 'pointer', marginBottom: '1rem'}}
                    >
                        Update Zip Code
                    </Button>
                </div>
                <div>
                    <Button
                        disabled={loading}
                        variant="contained"
                        onClick={() => {setDisplayTitle('Update Social Profiles'); setUpdateFormType('socialMedia'); setShowUpdateForm(true)}}
                        style={{fontWeight: '300', fontSize: '1rem', textTransform: 'none', color: 'green', cursor: 'pointer', marginBottom: '1rem'}}
                        >
                        Update Social Profiles
                    </Button>
                </div>

                <Dialog open={showUpdateForm} onClose={() => setShowUpdateForm(false)} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{loading ? <CircularProgress size={25} style={{color: 'forestgreen'}}/> : displayTitle}</DialogTitle>
                    <DialogContent>
                        {updateFormType === 'email' && <TextField
                            fullWidth
                            disabled={loading}
                            type="email"
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            placeholder="email address..."
                            value={emailAddress}
                            onChange={(event) => setEmailAddress(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                        {updateFormType === 'email' && <TextField
                            fullWidth
                            disabled={loading}
                            type="email"
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            placeholder="confirm email..."
                            value={emailConfirmation}
                            onChange={(event) => setEmailConfirmation(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                        {updateFormType === 'password' && <TextField
                            fullWidth
                            disabled={loading}
                            type="password"
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            placeholder="secure password..."
                            value={securePassword}
                            onChange={(event) => setSecurePassword(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                        {updateFormType === 'password' && <TextField
                            fullWidth
                            disabled={loading}
                            type="password"
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            placeholder="password confirmation..."
                            value={passwordConfirmation}
                            onChange={(event) => setPasswordConfirmation(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                        {updateFormType === 'image' && <TextField
                            disabled={loading}
                            type="file"
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            placeholder="image"
                            onChange={(event) => setImage(event.target.files[0])}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                        {updateFormType === 'description' && <TextField
                            fullWidth
                            multiline
                            rows={7}
                            disabled={loading}
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            value={description}
                            onChange={(event) => setDescription(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                        {updateFormType === 'website' && <TextField
                            fullWidth
                            disabled={loading}
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            value={website}
                            onChange={(event) => setWebsite(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                        {updateFormType === 'phone' && <TextField
                            fullWidth
                            disabled={loading}
                            type="number"
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            value={phoneNumber}
                            onChange={(event) => setPhoneNumber(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                        {updateFormType === 'zipCode' && <TextField
                            fullWidth
                            disabled={loading}
                            type="number"
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            value={zipCode}
                            onChange={(event) => setZipCode(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                        {updateFormType === 'socialMedia' && <TextField
                            fullWidth
                            disabled={loading}
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            label="facebook url"
                            value={facebookProfile.url !== '' && facebookProfile.url.indexOf('http://facebook.com/') === -1 ? `http://facebook.com/${facebookProfile.url}` : facebookProfile.url}
                            onChange={(event) => setFacebookProfile({type: 'facebook', url: event.target.value})}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                        {updateFormType === 'socialMedia' && <TextField
                            fullWidth
                            disabled={loading}
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            label="instagram url"
                            value={instagramProfile.url !== '' && instagramProfile.url.indexOf('http://instagram.com/') === -1 ? `http://instagram.com/${instagramProfile.url}` : instagramProfile.url}
                            onChange={(event) => setInstagramProfile({type: 'instagram', url: event.target.value})}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                        {updateFormType === 'socialMedia' && <TextField
                            fullWidth
                            disabled={loading}
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            label="twitter url"
                            value={twitterProfile.url !== '' && twitterProfile.url.indexOf('http://twitter.com/') === -1 ? `http://twitter.com/${twitterProfile.url}` : twitterProfile.url}
                            onChange={(event) => setTwitterProfile({type: 'twitter', url: event.target.value})}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowUpdateForm(false)} variant="contained" style={{backgroundColor: 'transparent', color: '#222', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>
                            Cancel
                        </Button>
                        <Button disabled={loading} onClick={updateField} variant="contained" style={{backgroundColor: '#3f8b24', color: '#fff', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Grid>
    </Grid>)
}

export default withStyles(GlobalStyle)(Settings);
import React, { useEffect } from 'react';
import {
    Container,
    AppBar,
    Toolbar,
    Grid,
    Paper,
    Typography,
    Button,
    withStyles
  } from '@material-ui/core';
  import TrackingIcon from '@material-ui/icons/Assessment';
  import LockIcon from '@material-ui/icons/Lock';
  import NotificationsIcon from '@material-ui/icons/NotificationsActive';
  import TuneIcon from '@material-ui/icons/Tune';
  import GlobalStyle from '../style/Global';
  import wordmark from '../assets/images/wordmark.png';
  import appleBadge from '../assets/images/app-store.png';
  import googleBadge from '../assets/images/play-store.png';
  import appPreview from '../assets/images/landing_page_preview.png';
  import volunteering from '../assets/images/volunteering.jpeg';
  import moment from 'moment';
    
  function LandingPage({classes, setScene}) {

    // useEffect(() => {
    //   fetch('https://us-central1-champs-change-app.cloudfunctions.net/getFeaturedOrgs', {
    //     method: 'POST',
    //     header: {
    //       'Content-Type': 'application/json'
    //     },
    //     mode: 'cors',
    //     body: JSON.stringify({
    //         donorId: donorId,
    //         donorEmail: donorEmail,
    //         donorEnvironment: donorEnvironment,
    //         publicToken: publicToken,
    //         metadata: metadata,
    //         hasFundingSource: hasFundingSource,
    //     })
    //   })
    //   .then(() => {
    //       //
    //   .catch((error) => {
    //       //
    //   })
    // }, [])
  
    return (
      <Grid container style={{height: '100vh', backgroundColor: '#3f8b24'}}>
        <Container maxWidth="lg">
          <Grid container item>
            <Grid item xs={12}>
              <AppBar position="static" style={{backgroundColor: 'transparent', boxShadow: 'none'}}>
                <Toolbar className={classes.mobileJustifyContentCenter}>
                  <img onClick={() => setScene('index')} src={wordmark} style={{cursor: 'pointer', marginTop: '0.5rem', height: '4rem'}}/>
                  <div className={classes.desktop} style={{flexGrow: 1}}/>
                  <Button className={classes.desktop} onClick={() => setScene('log in')} style={{color: '#f5f5f5', textTransform: 'none', marginRight: '1rem'}}>Log In</Button>
                  <Button className={classes.desktop} onClick={() => setScene('sign up')} style={{backgroundColor: '#fad84a', color: '#3f8b24', textTransform: 'none'}}>Add My Organization</Button>
                </Toolbar>
              </AppBar>
            </Grid>
            <Grid container item xs={12} className={classes.mobileJustifyContentCenter}>
              <Grid item md={6} style={{padding: '1rem'}}>
                <Typography
                  style={{
                    color: '#f5f5f5',
                    fontSize: '2.5rem',
                    fontFamily: 'Helvetica',
                    fontWeight: '700',
                    lineHeight: 1,
                    marginTop: '8rem',
                    marginBottom: '1rem'
                  }}
                  className={classes.mobileTextAlignCenter}
                >
                  <span style={{color: '#fad84a'}}>Spare Change</span><br/>for Your Favorite<br/>Organization
                </Typography>
                <Typography
                  style={{
                    color: '#fff',
                    fontSize: '1rem',
                    fontFamily: 'Helvetica',
                    fontWeight: '200',
                    lineHeight: 1,
                    marginBottom: '1rem',
                  }}
                  className={classes.mobileCenter}
                >
                  Automatically round up purchases and donate to causes you care about.
                </Typography>
                <div className={classes.mobileTextAlignCenter}>
                    <img onClick={() => window.open('https://apps.apple.com/us/app/champs-change/id1557127094', '_blank')} src={appleBadge} style={{cursor: 'pointer', height: '3rem'}}/>
                    <img onClick={() => window.open('https://play.google.com/store/apps/details?id=com.champschange.app', '_blank')} src={googleBadge} style={{cursor: 'pointer', height: '3rem'}}/>
                </div>
              </Grid>
              <Grid item md={6} style={{textAlign: 'center'}}>
                <img src={appPreview} className={classes.appPreview}/>
              </Grid>
            </Grid>
            <Grid item md={6} style={{backgroundColor: '#4fa127', padding: '2rem'}}>
              <img src={volunteering} style={{width: '100%'}}/>
            </Grid>
            <Grid item md={6} style={{backgroundColor: '#4fa127', padding: '2rem'}}>
              <Typography
                  style={{
                    color: '#fff',
                    fontSize: '2.5rem',
                    fontFamily: 'Helvetica',
                    fontWeight: '700',
                    lineHeight: 1,
                    marginBottom: '1rem'
                  }}
                >
                  Our Mission
                </Typography>
                <Typography
                  style={{
                    color: '#fff',
                    fontSize: '1rem',
                    fontFamily: 'Helvetica',
                    fontWeight: '400',
                    lineHeight: 2,
                    marginBottom: '1rem'
                  }}
                >
                  We are working to create funding sources for organizations that tackle issues plaguing Black communities across America. 
                  Since the Civil Rights Movement, Black people have had to fight for everything from access to nutrient-rich foods to healthcare. 
                  But in this unprecedented time these organizations are in desperate need of funding. It is often hard to know where and how to get involved, 
                  that’s why we created Champs Change – to connect individuals like you to organizations in their backyard doing the necessary work to affect positive change.
                </Typography>
            </Grid>
            <Grid item md={6} style={{backgroundColor: '#fff', padding: '2rem'}}>
              <Typography
                  style={{
                    color: '#3f8b24',
                    fontSize: '2.5rem',
                    fontFamily: 'Helvetica',
                    fontWeight: '700',
                    lineHeight: 1,
                    marginBottom: '1rem'
                  }}
                >
                  Features
                </Typography>
                <Typography
                  style={{
                    color: '#222',
                    fontSize: '1rem',
                    fontFamily: 'Helvetica',
                    fontWeight: '200',
                    lineHeight: 1.5,
                    marginBottom: '1rem'
                  }}
                >
                  Ease of use, safety, and impact drive everything we do. Our goal is to provide technical solutions that create a seamless philanthropic experience. 
                  We believe changing the world for the better is possible with the right tools and focus.
                </Typography>
            </Grid>
            <Grid container item md={6} style={{backgroundColor: '#fff', padding: '2rem'}}>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    color: '#3f8b24',
                    fontSize: '1rem',
                    fontFamily: 'Helvetica',
                    fontWeight: '700',
                    lineHeight: 1,
                    marginBottom: '0.5rem',
                  }}
                >
                  <TrackingIcon style={{color: '#444', verticalAlign: 'sub'}}/> Contribution History
                </Typography>
                <Typography
                  style={{
                    color: '#222',
                    fontSize: '1rem',
                    fontFamily: 'Helvetica',
                    fontWeight: '200',
                    lineHeight: 1,
                    marginBottom: '1rem'
                  }}
                >
                  Keep track of your giving and export monthly or annual donation reports.
                </Typography>
              </Grid>
              <Grid item md={6}>
              <Typography
                  style={{
                    color: '#3f8b24',
                    fontSize: '1rem',
                    fontFamily: 'Helvetica',
                    fontWeight: '700',
                    lineHeight: 1,
                    marginBottom: '0.5rem',
                  }}
                >
                  <LockIcon style={{color: '#444', verticalAlign: 'sub'}}/> Bank-Level Security
                </Typography>
                <Typography
                  style={{
                    color: '#222',
                    fontSize: '1rem',
                    fontFamily: 'Helvetica',
                    fontWeight: '200',
                    lineHeight: 1,
                    marginBottom: '1rem'
                  }}
                >
                  Our apps use industry-standard encryption and we never store your banking credentials.
                </Typography>
              </Grid>
              <Grid item md={6}>
              <Typography
                  style={{
                    color: '#3f8b24',
                    fontSize: '1rem',
                    fontFamily: 'Helvetica',
                    fontWeight: '700',
                    lineHeight: 1,
                    marginBottom: '0.5rem',
                  }}
                >
                  <NotificationsIcon style={{color: '#444', verticalAlign: 'sub'}}/> News & Updates
                </Typography>
                <Typography
                  style={{
                    color: '#222',
                    fontSize: '1rem',
                    fontFamily: 'Helvetica',
                    fontWeight: '200',
                    lineHeight: 1,
                    marginBottom: '1rem'
                  }}
                >
                  We keep you up-to-date with how your funds are being used and interesting articles.
                </Typography>
              </Grid>
              <Grid item md={6}>
              <Typography
                  style={{
                    color: '#3f8b24',
                    fontSize: '1rem',
                    fontFamily: 'Helvetica',
                    fontWeight: '700',
                    lineHeight: 1,
                    marginBottom: '0.5rem',
                  }}
                >
                  <TuneIcon style={{color: '#444', verticalAlign: 'sub'}}/> Complete Control
                </Typography>
                <Typography
                  style={{
                    color: '#222',
                    fontSize: '1rem',
                    fontFamily: 'Helvetica',
                    fontWeight: '200',
                    lineHeight: 1,
                    marginBottom: '1rem'
                  }}
                >
                  Pause and resume spare change contributions at anytime in your app settings.
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={12} style={{backgroundColor: '#3f8b24', padding: '2rem'}}>
              <Typography
                style={{
                  color: '#fad84a',
                  fontSize: '2rem',
                  fontFamily: 'Helvetica',
                  fontWeight: '700',
                  lineHeight: 1,
                  textAlign: 'center',
                  marginBottom: '0.25rem'
                }}
              >
                Make Change with Your Change
              </Typography>
              <Typography
                  style={{
                    color: '#fff',
                    fontSize: '1.5rem',
                    fontFamily: 'Helvetica',
                    fontWeight: '200',
                    lineHeight: 1,
                    textAlign: 'center',
                    marginBottom: '0.5rem',
                  }}
                >
                  Download the app and contribute today!
                </Typography>
            </Grid>
            {/* <Grid item xs={12}>
              <Typography
                  style={{
                    color: '#444',
                    fontSize: '1.5rem',
                    fontFamily: 'Helvetica',
                    fontWeight: '700',
                    lineHeight: 1,
                    textAlign: 'center',
                    margin: '2rem',
                  }}
                >
                  Featured Organizations
                </Typography>
            </Grid>
            <Grid container item xs={12} style={{justifyContent: 'center'}}>
              {[1,2,3].map((featuredOrg) => (<Grid item xs={12} md={4} style={{padding: '1rem'}}>
                <Paper style={{position: 'relative'}}>
                  <img src={volunteering} style={{width: '100%'}}/>
                  <div style={{position: 'absolute', bottom: 0, backgroundColor: 'rgba(63, 139, 36, 0.6)', width: '100%', minHeight: '3rem'}}>
                    <Typography
                      style={{
                        color: '#f5f5f5',
                        fontSize: '1.5rem',
                        fontFamily: 'Helvetica',
                        lineHeight: 1,
                        margin: '1rem',
                      }}
                    >
                      {`{{Organization Name}}`}
                    </Typography>
                  </div>
                </Paper>
              </Grid>))}
            </Grid> */}
            <Grid xs={12} style={{margin: '3rem'}}></Grid>
          </Grid>
        </Container>
        <Grid item xs={12} style={{textAlign: 'center', paddingTop: '2rem'}}>
          <a href="/legal" style={{textDecoration: 'none'}}>Terms of Use</a>&nbsp;|&nbsp;
          <a href="/legal" style={{textDecoration: 'none'}}>Privacy Policy</a>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'center', paddingTop: '0.5rem', paddingBottom: '1rem'}}>
          <span style={{fontFamily: 'Helvetica', fontWeight: '700'}}>&copy; {moment().format('YYYY')} Champ's Change</span>
        </Grid>
      </Grid>
    );
  }
  
  export default withStyles(GlobalStyle)(LandingPage);
  
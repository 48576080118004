const GlobalStyle = theme => ({
    hide: {
        display: 'none'
    },
    notchedOutline: {
        [`&.Mui-focused`]: {
            borderWidth: '5px',
            borderColor: 'red !important'
        }
    },
    input: {
        backgroundColor: '#fff',
        borderRadius: '25px',
        color: '#222',
        marginBottom: '1rem',
        '& $notchedOutline': {
            borderColor: '#ccc !important',
        },
        [`& input`]: {
            padding: '9px 10px'
        },
        [`& fieldset`]: {
            borderRadius: '25px'
        },
        '& label': {
            color: '#222',
        },
        '& label.Mui-focused': {
            color: '#222',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#fff',
            },
            '&:hover fieldset': {
              borderColor: '#fff',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#fff',
            },
          },
          '& .MuiSelect-select': {
              padding: '0.6rem',
              textAlign: 'left'
          }
    },
    selectNoBackground: {
        [`&::placeholder`]: {
            color: 'red'
        },
        [`& .MuiSelect-select:focus`]: {
            backgroundColor: 'transparent'
        }
    },
    inputHighlighted: {
        backgroundColor: '#fff',
        borderRadius: '25px',
        '& $notchedOutline': {
            borderColor: '#f5b02e !important',
        },
        [`& input`]: {
            width: '7rem',
            padding: '9px 10px'
        },
        [`& fieldset`]: {
            borderRadius: '25px',
        },
    },
    success: {
        position: 'absolute',
        zIndex: 3,
        top: '1rem',
        right: '2rem',
        marginRight: '2rem',
        backgroundColor: 'green'
    },
    error: {
        position: 'absolute',
        zIndex: 3,
        top: '1rem',
        right: '2rem',
        marginRight: '2rem',
        backgroundColor: 'red'
    },
    mobile: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },  
    },
    desktop: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    mobileTextAlignCenter: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        },
    },
    mobileJustifyContentCenter: {
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        },   
    },
    appPreview: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%'
        },
        [theme.breakpoints.up('sm')]: {
            height: '100%'
        },   
    }
})

export default GlobalStyle;
import { useState, useEffect } from 'react';
import GlobalStyle from '../../style/Global';
import {
    withStyles,
    Grid,
    Typography,
    TextField,
    Button,
    Snackbar,
    CircularProgress,
    Paper
} from '@material-ui/core';

import {
    DataGrid
} from '@material-ui/data-grid';

import BackIcon from '@material-ui/icons/KeyboardBackspace';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

function Donors ({
    DB,
    organizationId,
    setDashboardScene
}) {
    const [loading, setLoading] = useState(true);
    const [donors, setDonors] = useState([]);

    const columns = [
        {field: 'name', headerName: 'Name', width: 200},
        {field: 'fundsRaised', headerName: 'Funds Raised', width: 250},
        {field: 'contributions', headerName: 'Contributions', width: 250},
        {field: 'status', headerName: 'Status', width: 150},
        {field: 'active', headerName: 'Active', width: 100}
    ]

    useEffect(() => {
        DB.collection('donors').where('organizations', 'array-contains', organizationId).get()
        .then((donorsSnapshot) => {
            const donorsData = [];

            donorsSnapshot.forEach((donorDoc) => {
                let donor = donorDoc.data();
                donor.name = `${donor.firstName} ${donor.lastName}`;
                donor.fundsRaised = `$${parseFloat(donor.private.fundsRaised/100).toFixed(2)}`;
                donor.contributions = donor.private.contributions;
                donor.status = donor.status;
                donor.active = donor.active;

                donorsData.push(donor);
            })

            setDonors(donorsData);
            setLoading(false);
        })
    }, []);

    return (<Grid item container xs={12}>
        <Grid item container xs={12} style={{minHeight: '3rem', backgroundColor: '#444', textAlign: 'center'}}>
            <Grid item xs={12} style={{margin: '1rem', textAlign: 'left'}}>
                <Typography variant="h6" component="h1" style={{fontWeight: '300', fontSize: '2rem', color: '#f5f5f5'}}>Donors</Typography>
                <Typography onClick={() => setDashboardScene('index')} variant="h6" component="h1" style={{fontFamily: 'helvetica', fontSize: '1rem', cursor: 'pointer', fontWeight: '200', color: '#f5f5f5'}}><BackIcon style={{verticalAlign: 'bottom'}}/> go back</Typography>
            </Grid>
        </Grid>
        <Grid item xs={12} style={{color: '#222'}}>
            <DataGrid autoHeight disableMultipleSelection onRowClick={(param) => console.log(param)} loading={loading} rows={donors} columns={columns} pageSize={25}/>
        </Grid>
    </Grid>)
}

export default withStyles(GlobalStyle)(Donors);
import React, { Fragment, useState } from 'react';

/* UI COMPONENTS */
import {
    withStyles,
    Grid,
    Typography,
    TextField,
    MenuItem,
    Button,
    SnackbarContent,
    CircularProgress,
    Paper
} from '@material-ui/core';


/* ASSETS */
import logo from '../assets/images/wordmark_dark_stacked.png';
import GlobalStyle from '../style/Global';


function SignUp({ classes, AUTH, DB, setScene }) {
    window.scrollTo(0, 0);

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [signUpStep, setSignUpStep] = useState(1);
    const [emailAddress, setEmailAddress] = useState("");
    const [emailConfirmation, setEmailConfirmation] = useState("");
    const [securePassword, setSecurePassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [confirmNumber, setConfirmNumber] = useState("");
    const [ein, setEin] = useState("");
    const [website, setWebsite] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState("select category");

    const categories = [
        'Community',
        'Housing',
        'Training',
        'Medical',
        'Sports Clubs',
        'Arts & Culture',
        'Public Service/Military',
        'Education',
        'Children',
        'Environmental',
        'Religious',
        'Disability',
        'Elderly',
        'Financial',
        'Poverty Relief',
        'Hunger & Nutrition',
        'Women & Girls',
        'Health & Infectious Diseases',
    ];

    const showFeedback = (message, type) => {
        setMessage({
            style: type,
            content: message
        });

        setTimeout(() => {setMessage(null)}, 7000);
    }

    const createOrganization = () => {
        setLoading(true);
        if (!emailAddress || !securePassword || !organizationName || !contactPerson || !phoneNumber || !ein || !website || !city || !state || !description || !category || category === 'select category') {
            showFeedback("All fields required", classes.error);
            setLoading(false);
        } else if (emailAddress !== emailConfirmation) {
            showFeedback("Email mismatch", classes.error);
            setLoading(false);
        } else if (securePassword !== passwordConfirmation) {
            showFeedback("Password mismatch", classes.error);
            setLoading(false);
        } else if (phoneNumber !== confirmNumber) {
            showFeedback("Phone mismatch", classes.error);
            setLoading(false);
        } else {
            AUTH.createUserWithEmailAndPassword(emailAddress, securePassword)
            .then((newUserData) => {
                const id = newUserData.user.uid;

                DB.collection('organizations').doc(id).set({
                    id,
                    emailAddress,
                    organizationName,
                    contactPerson,
                    phoneNumber,
                    ein,
                    website,
                    city,
                    state,
                    description,
                    category,
                    socialMedia: [
                        {type: 'facebook', url: ''},
                        {type: 'instagram', url: ''},
                        {type: 'twitter', url: ''}
                    ],
                    private: {
                        fundsRaised: 0,
                        contributions: 0,
                        donors: 0,
                        serviceFee: 25,
                        donorExportEnabled: false,
                        payoutsSetup: false,
                        payoutDetails: null,
                        active: false
                    }
                })
                .then(() => {
                    // setEmailAddress(null);
                    // setOrganizationName(null);
                    // setContactPerson(null);
                    // setPhoneNumber(null);
                    // setEin(null);
                    // setWebsite(null);
                    // setZipCode(null);
                    // setDescription(null);
                    // setSignUpStep(1);
                    showFeedback("Your account has been created and is pending review. We'll be in touch as soon as possible", classes.success)
                    setScene('dashboard');
                })
                .catch((error) => {
                    showFeedback(error.message, classes.error)
                    setLoading(false);
                })
            })
            .catch((error) => {
                showFeedback(error.message, classes.error)
                setLoading(false)
            })
        }
    };

    return(
        <div style={{backgroundColor: '#3f8b24'}}>
            {message && <SnackbarContent
                style={{zIndex: 99999999999}}
                className={message.style}
                message={message.content}
            />}
            <Grid container style={{minHeight: '100vh', paddingTop: '3rem'}} justify="center">
                <Grid item xs={10} md={4} style={{textAlign: 'center'}}>
                    <Paper elevation={3} style={{padding: '2rem', borderRadius: '25px'}}>
                    <img onClick={() => setScene('index')} src={logo} alt="logo" style={{cursor: 'pointer', width: '10rem', maxWidth: '80%'}}/>
                    <div>
                        {!loading && <Typography variant="h6" component="h1" style={{fontWeight: '300', color: '#222'}}>Sign Up</Typography>}
                        {loading && <CircularProgress size={20}/>}
                        <Typography variant="caption" component="p" style={{fontWeight: '200', color: '#222', marginBottom: '1rem'}}>Have an account? <span style={{cursor: 'pointer', textDecoration: 'none', color: '#3f8b24'}} onClick={() => setScene('log in')}>Log In</span></Typography>
                        {signUpStep === 1 && <TextField
                            fullWidth
                            disabled={loading}
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            placeholder="organization name..."
                            value={organizationName}
                            onChange={(event) => setOrganizationName(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                        {signUpStep === 1 && <TextField
                            fullWidth
                            disabled={loading}
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            placeholder="contact person..."
                            value={contactPerson}
                            onChange={(event) => setContactPerson(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                        {signUpStep === 1 && <TextField
                            fullWidth
                            disabled={loading}
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            placeholder="website..."
                            value={website && website.indexOf('http://') === -1 ? `http://${website}` : website}
                            onChange={(event) => setWebsite(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                        {signUpStep === 1 && <TextField
                            fullWidth
                            select
                            disabled={loading}
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            placeholder="category..."
                            value={category}
                            onChange={(event) => setCategory(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        >
                                <MenuItem key="placeholder" value="select category" disabled>select category</MenuItem>
                                {categories.map((cat) => <MenuItem key={cat} value={cat}>{cat}</MenuItem>)}
                        </TextField>}
                        {signUpStep === 1 && <TextField
                            fullWidth
                            disabled={loading}
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            placeholder="employer identification number..."
                            value={ein}
                            type="number"
                            onChange={(event) => setEin(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                        {signUpStep === 1 && <TextField
                            fullWidth
                            disabled={loading}
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            placeholder="city..."
                            value={city}
                            onChange={(event) => setCity(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                        {signUpStep === 1 && <TextField
                            fullWidth
                            disabled={loading}
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            placeholder="state..."
                            value={state}
                            onChange={(event) => setState(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                        {signUpStep === 1 && <TextField
                            fullWidth
                            multiline
                            rows={7}
                            disabled={loading}
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            placeholder="description..."
                            value={description}
                            onChange={(event) => setDescription(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                        {signUpStep === 2 && <TextField
                            fullWidth
                            disabled={loading}
                            type="email"
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            placeholder="email address..."
                            value={emailAddress}
                            onChange={(event) => setEmailAddress(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                        {signUpStep === 2 && <TextField
                            fullWidth
                            disabled={loading}
                            type="email"
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            placeholder="confirm email..."
                            value={emailConfirmation}
                            onChange={(event) => setEmailConfirmation(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                        {signUpStep === 2 && <TextField
                            fullWidth
                            disabled={loading}
                            type="number"
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            placeholder="phone number..."
                            value={phoneNumber}
                            onChange={(event) => setPhoneNumber(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                        {signUpStep === 2 && <TextField
                            fullWidth
                            disabled={loading}
                            type="number"
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            placeholder="confirm phone..."
                            value={confirmNumber}
                            onChange={(event) => setConfirmNumber(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                        {signUpStep === 2 && <TextField
                            fullWidth
                            disabled={loading}
                            type="password"
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            placeholder="secure password..."
                            value={securePassword}
                            onChange={(event) => setSecurePassword(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                        {signUpStep === 2 && <TextField
                            fullWidth
                            disabled={loading}
                            type="password"
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            placeholder="confirm password..."
                            value={passwordConfirmation}
                            onChange={(event) => setPasswordConfirmation(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />}
                        {signUpStep === 1 && <Button disabled={loading} onClick={() => setSignUpStep(2)} fullWidth variant="contained" style={{backgroundColor: '#3f8b24', color: '#fff', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>next</Button>}
                        {signUpStep === 2 && <Button disabled={loading} onClick={() => createOrganization()} fullWidth variant="contained" style={{backgroundColor: '#3f8b24', color: '#fff', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>sign up</Button>}
                        <Button disabled={loading} onClick={() => signUpStep === 1 ? setScene('index') : setSignUpStep(1)} fullWidth variant="contained" style={{backgroundColor: '#ccc', color: '#222', borderRadius: '25px', textTransform: 'none', marginBottom: '1rem'}}>go back</Button>
                        <Typography variant="caption" style={{marginTop: '1.5rem', paddingBottom: '1rem', color: '#222'}}>
                            By logging In or registering for this website you agree to our <a style={{color: '#3f8b24'}} href="/legal">Privacy & Terms</a> policies
                        </Typography>
                    </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );

}

export default withStyles(GlobalStyle)(SignUp);
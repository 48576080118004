import React, { Fragment, useState, useEffect } from 'react';

import Donors from './Donors';
import Contributions from './Contributions';
import Updates from './Updates';
import Settings from './Settings';

/* UI COMPONENTS */
import {
    AppBar,
    Toolbar,
    withStyles,
    Grid,
    Typography,
    TextField,
    Button,
    CircularProgress,
    Paper,
    SnackbarContent,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core';
import moment from 'moment';
import NumberFormat from 'react-number-format';

import PeopleIcon from '@material-ui/icons/SupervisedUserCircle';
import SettingsIcon from '@material-ui/icons/Tune';
import MoneyIcon from '@material-ui/icons/MonetizationOn';
import CashIcon from '@material-ui/icons/LocalAtm';
import BackIcon from '@material-ui/icons/KeyboardBackspace';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import UpdateIcon from '@material-ui/icons/RssFeed';


/* ASSETS */
import wordmark from '../../assets/images/wordmark.png';
import GlobalStyle from '../../style/Global';


function Dashboard({ classes, firestore, AUTH, DB, CDN, setScene }) {
    window.scrollTo(0, 0);

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [user, setUser] = useState(null);
    const [dashboardScene, setDashboardScene] = useState('index');

    const showFeedback = (message, type) => {
        setMessage({
            style: type,
            content: message
        });

        setTimeout(() => {setMessage(null)}, 7000);
    }

    const [donors, setDonors] = useState([1,2,3,4,5,6,7]);

    const logOut = () => {
        AUTH.signOut();
        showFeedback('Logged out', classes.error);
        setScene('log in');
    };

    const setupPayoutAccount = (org) => {
        setLoading(true);

        fetch('https://us-central1-champs-change-app.cloudfunctions.net/setupPayoutAccount', {
            method: 'POST',
            header: {
              'Content-Type': 'application/json'
            },
            mode: 'cors',
            body: JSON.stringify({
                organizationId: org.id,
                organizationEmail: org.emailAddress,
                testing: true
            })
        }).then((response) => {
            response.text()
            .then((data) => {
                window.location.href = data;
            })
            .catch((error) => {
                showFeedback(error.message, classes.error)
            })
        })
        .catch((error) => {
            showFeedback(error.message, classes.error)
        })
    }

    useEffect(() => {
        const currentUrl = new URL(window.location);
        // AUTH.onAuthStateChanged((currentUser) => {
        //     if (currentUser) {
                // const id = currentUser.uid;
    
                DB.collection('organizations').doc(AUTH.currentUser.uid).get()
                .then((userDocument) => {
                    const organization = userDocument.data();
                    organization.id = userDocument.id;
                    
                    const checkAccount = currentUrl.searchParams.get('checkConnectStatus');
                    const getPayoutAccountLink = currentUrl.searchParams.get('getPayoutAccountLink');
    
                    if (checkAccount === 'true') {
                        fetch('https://us-central1-champs-change-app.cloudfunctions.net/checkPayoutAccountStatus', {
                            method: 'POST',
                            header: {
                              'Content-Type': 'application/json'
                            },
                            mode: 'cors',
                            body: JSON.stringify({
                                organization: organization
                            })
                        }).then((response) => {
                            response.json()
                            .then((orgData) => {
                                setUser(orgData);
                                setLoading(false);
                                window.history.replaceState(null, '', '');
                            })
                            .catch((error) => {
                                showFeedback(error.message, classes.error)
                            })
                        })
                        .catch((error) => {
                            showFeedback(error.message, classes.error)
                        })
                    } else if (getPayoutAccountLink === 'true') {
                        setupPayoutAccount(organization)
                    } else {
                        setUser(organization);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    showFeedback(error.message, classes.error)
                    setLoading(false);
                })
        //     } else {
        //         showFeedback('Please log in', classes.error)
        //         return setScene('log in');
        //     }
        // })
    }, []);


    return(
        <div>
            <AppBar position="static" style={{backgroundColor: '#3f8b24', boxShadow: 'none'}}>
                <Toolbar>
                    <img src={wordmark} style={{height: '2rem'}}/>
                    <div style={{flexGrow: 1}}/>
                    <Button onClick={logOut} style={{color: '#f5f5f5', textTransform: 'none', marginRight: '1rem'}}>log out</Button>
                </Toolbar>
            </AppBar>
            {dashboardScene === 'index' && <Grid container style={{backgroundColor: '#f5f5f5'}}>
                <Grid item container xs={12} style={{minHeight: '15rem', backgroundColor: '#444', textAlign: 'center'}}>
                    {message && <SnackbarContent
                        style={{zIndex: 99999999999}}
                        className={message.style}
                        message={message.content}
                    />}
                    {user && !user.private.payoutsSetup && <Grid item xs={12} style={{backgroundColor: 'yellow', padding: '1rem'}}>
                        <Typography style={{fontWeight: '300', color: '#222', marginBottom: '0.5rem'}}>In order to receive payouts you must have an active bank account.</Typography>
                        <Button onClick={() => {setLoading(true); setupPayoutAccount(user)}} variant="contained" style={{backgroundColor: 'green', color: '#f5f5f5', textTransform: 'none', marginRight: '1rem'}}>Setup Payout Account</Button>
                    </Grid>}
                    {user && !user.private.active && user.private.payoutsSetup && <Grid item xs={12} style={{backgroundColor: 'yellow', padding: '1rem'}}>
                        <Typography style={{fontWeight: '300', color: '#222', marginBottom: '0.5rem'}}>Your account is setup and pending review.</Typography>
                    </Grid>}
                    <Grid item xs={12} style={{margin: '1rem'}}>
                        <Typography variant="h6" component="h1" style={{fontWeight: '300', color: '#f5f5f5'}}>{(user && !loading) ? user.organizationName : <CircularProgress style={{color: '#f5f5f5'}}/>}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} style={{padding: '1rem'}}>
                        <Typography variant="h6" component="h1" style={{fontFamily: 'helvetica', fontWeight: '200', fontSize: '3rem', lineHeight: 1, color: '#f5f5f5'}}>{user && user.private.donors}</Typography>
                        <Typography variant="h6" component="h1" style={{fontWeight: '300', color: '#f5f5f5'}}>donors</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} style={{padding: '1rem'}}>
                        <Typography variant="h6" component="h1" style={{fontFamily: 'helvetica', fontWeight: '200', fontSize: '3rem', lineHeight: 1, color: '#f5f5f5'}}>${user && parseFloat(user.private.fundsRaised/100).toFixed(2)}</Typography>
                        <Typography variant="h6" component="h1" style={{fontWeight: '300', color: '#f5f5f5'}}>funds raised</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} style={{padding: '1rem'}}>
                        <Typography variant="h6" component="h1" style={{fontFamily: 'helvetica', fontWeight: '200', fontSize: '3rem', lineHeight: 1, color: '#f5f5f5'}}>{user && user.private.contributions}</Typography>
                        <Typography variant="h6" component="h1" style={{fontWeight: '300', color: '#f5f5f5'}}>contributions</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4} style={{padding: '2rem'}}>
                    <Paper elevation={3} onClick={() => setDashboardScene('donors')} style={{textAlign: 'center', padding: '4rem', cursor: 'pointer'}}>
                        <PeopleIcon style={{color: '#3f8b24', fontSize: '7rem'}}/>
                        <Typography variant="h6" component="h1" style={{fontFamily: 'helvetica', fontWeight: '200', fontSize: '2rem', lineHeight: 1}}>donors</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} style={{padding: '2rem', cursor: 'pointer'}}>
                    <Paper elevation={3} onClick={() => setDashboardScene('contributions')} style={{textAlign: 'center', padding: '4rem', cursor: 'pointer'}}>
                        <MoneyIcon style={{color: '#3f8b24', fontSize: '7rem'}}/>
                        <Typography variant="h6" component="h1" style={{fontFamily: 'helvetica', fontWeight: '200', fontSize: '2rem', lineHeight: 1}}>contributions</Typography>
                    </Paper>
                </Grid>
                {/* <Grid item xs={12} md={6} style={{padding: '2rem'}}>
                    <Paper elevation={3} onClick={() => setDashboardScene('updates')} style={{textAlign: 'center', padding: '4rem', cursor: 'pointer'}}>
                        <UpdateIcon style={{color: '#3f8b24', fontSize: '7rem'}}/>
                        <Typography variant="h6" component="h1" style={{fontFamily: 'helvetica', fontWeight: '200', fontSize: '2rem', lineHeight: 1}}>updates</Typography>
                    </Paper>
                </Grid> */}
                <Grid item xs={12} md={4} style={{padding: '2rem'}}>
                    <Paper elevation={3} onClick={() => setDashboardScene('settings')} style={{textAlign: 'center', padding: '4rem', cursor: 'pointer'}}>
                        <SettingsIcon style={{color: '#3f8b24', fontSize: '7rem'}}/>
                        <Typography variant="h6" component="h1" style={{fontFamily: 'helvetica', fontWeight: '200', fontSize: '2rem', lineHeight: 1}}>settings</Typography>
                    </Paper>
                </Grid>
            </Grid>}
            {dashboardScene === 'donors' && <Donors DB={DB} organizationId={AUTH.currentUser.uid} setDashboardScene={setDashboardScene} />}
            {dashboardScene === 'contributions' && <Contributions DB={DB} organizationId={AUTH.currentUser.uid} setDashboardScene={setDashboardScene} />}
            {dashboardScene === 'updates' && <Updates firestore={firestore} DB={DB} CDN={CDN} organization={user} organizationId={AUTH.currentUser.uid} setMessage={setMessage} setDashboardScene={setDashboardScene} />}
            {dashboardScene === 'settings' && <Settings DB={DB} CDN={CDN} organization={user} currentUser={AUTH.currentUser.uid} setMessage={setMessage} setDashboardScene={setDashboardScene} />}
        </div>
    );

}

export default withStyles(GlobalStyle)(Dashboard);
import { useState, useEffect, useCallback } from 'react';
import { PlaidLink } from 'react-plaid-link';
import {
    withStyles,
    CircularProgress,
    SnackbarContent
} from '@material-ui/core';
import GlobalStyle from '../style/Global';


function Link ({
    DB,
    classes
}) {
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState(null);
    const [donorId, setDonorId] = useState(null);
    const [isFundingSource, setIsFundingSource] = useState(null);
    const [config, setConfig] = useState(null);
    const [plaidButton, setPlaidButton] = useState(null);
    const [token, setToken] = useState(null);

    const showFeedback = (message, type) => {
        setMessage({
            style: type,
            content: message
        });

        setTimeout(() => {setMessage(null)}, 7000);
    }

    const onExit = useCallback(() => {
        setLoading(true);

        const urlParams = new URLSearchParams(window.location.search);
        const donorId = urlParams.get('donorId');

        DB.collection('donors').doc(donorId)
        .update({
            dispatch: 'exit'
        })
        .catch((error) => {
            showFeedback(error.message, classes.error);
        })
    })

    const onSuccess = useCallback((publicToken, metadata) => {
        setLoading(true);

        const urlParams = new URLSearchParams(window.location.search);
        const donorId = urlParams.get('donorId');
        const donorEmail = urlParams.get('donorEmail');
        const donorName = urlParams.get('donorName');
        const donorEnvironment = urlParams.get('donorEnvironment');
        const hasFundingSource = urlParams.get('hasFundingSource');

        fetch('https://us-central1-champs-change-app.cloudfunctions.net/addAccount', {
            method: 'POST',
            header: {
              'Content-Type': 'application/json'
            },
            mode: 'cors',
            body: JSON.stringify({
                donorId: donorId,
                donorEmail: donorEmail,
                donorName: donorName,
                donorEnvironment: donorEnvironment,
                publicToken: publicToken,
                metadata: metadata,
                hasFundingSource: hasFundingSource,
            })
        })
        .then(() => {
            DB.collection('donors').doc(donorId)
            .update({
                dispatch: 'account added'
            })
            .catch((error) => {
                showFeedback(error.message, classes.error);
            })        })
        .catch((error) => {
            // ADD LOGGING
            DB.collection('donors').doc(donorId)
            .update({
                dispatch: error.message
            })
            .catch((error) => {
                showFeedback(error.message, classes.error);
            })
        })
    }, []);


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const donorEnvironment = urlParams.get('donorEnvironment');
        const hasFundingSource = urlParams.get('hasFundingSource');
        const products = hasFundingSource ? 'transactions' : 'auth,transactions';

        fetch('https://us-central1-champs-change-app.cloudfunctions.net/plaidLink', {
            method: 'POST',
            header: {
              'Content-Type': 'application/json'
            },
            mode: 'cors',
            body: JSON.stringify({
                products: products,
                donorEnvironment: donorEnvironment
            })
        }).then((response) => {
            response.text()
            .then((data) => {
                setToken(data);

                // setConfig({
                //     token: data,
                //     onSuccess,
                //     onExit
                //     // ...
                // });
                setLoading(false);
            })
            .catch((error) => {
                showFeedback(error.message, classes.error)
            })
        })
    }, [])

    return (<div style={{
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        width: '100vw',
        height: '100vh',
        textAlign: 'center'
    }}>
        {message && <SnackbarContent
            style={{zIndex: 99999999999}}
            className={message.style}
            message={message.content}
        />}
        {loading && <CircularProgress size={50} style={{marginTop: '20vh', color: 'forestgreen'}}/>}
        {!loading && token && <PlaidLink
            ref={(ref) => setPlaidButton(ref)}
            token={token}
            onSuccess={onSuccess}
            onLoad={() => {
                // setTimeout(() => {
                    document.getElementsByTagName('button')[0].click()
                // }, 2000)
            }}
            onExit={onExit}
            style={{
                display: 'none',
                backgroundColor: '#f5f5f5',
                color: 'forestgreen',
                fontSize: '3rem',
                width: '100vw',
                height: '100vh'
            }}
        >
            Connect Account
        </PlaidLink>}
    </div>)
}

export default withStyles(GlobalStyle)(Link);
import React, { Fragment, useState } from 'react';

/* UI COMPONENTS */
import {
    withStyles,
    Grid,
    Typography,
    TextField,
    Button,
    CircularProgress,
    SnackbarContent,
    Paper
} from '@material-ui/core';

import {
    Alert
} from '@material-ui/lab';

/* ASSETS */
import logo from '../assets/images/wordmark_dark_stacked.png';
import GlobalStyle from '../style/Global';


function LogIn({ classes, AUTH, DB, setScene }) {
    window.scrollTo(0, 0);

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const showFeedback = (message, type) => {
        setMessage({
            style: type,
            content: message
        });

        setTimeout(() => {setMessage(null)}, 3000);
    }

    const authenticateUser = () => {
        setLoading(true);
        if (!email || !password) {
            showFeedback("All fields required", classes.error);
            setLoading(false);
        } else {
            AUTH.signInWithEmailAndPassword(email, password)
            .then((currentUser) => {
                DB.collection('organizations').doc(currentUser.user.uid).get()
                .then((currentUserDocument) => {
                    if (currentUserDocument.exists) {
                        // SET USER
                        setLoading(false);
                        setScene('dashboard')
                    } else {
                        showFeedback('Please use donor app to manage your account', classes.error);
                        setLoading(false);
                    }
                })
            })
            .catch((error) => {
                showFeedback(error.message, classes.error)
                setLoading(false);
            })
        }
    };

    return(
        <div style={{backgroundColor: '#3f8b24'}}>
            {message && <SnackbarContent
                style={{zIndex: 99999999999}}
                className={message.style}
                message={message.content}
            />}
            <Grid container style={{minHeight: '100vh', paddingTop: '3rem'}} justify="center">
                <Grid item xs={10} md={4} style={{textAlign: 'center'}}>
                    <Paper elevation={3} style={{padding: '2rem', borderRadius: '25px'}}>
                    <img onClick={() => setScene('index')} src={logo} alt="logo" style={{cursor: 'pointer', width: '10rem', maxWidth: '80%'}}/>
                    <div>
                        {!loading && <Typography variant="h6" component="h1" style={{fontWeight: '300', color: '#222'}}>Log In</Typography>}
                        {loading && <CircularProgress size={20}/>}
                        <Typography variant="caption" component="p" style={{fontWeight: '200', color: '#222', marginBottom: '1rem'}}>Need an account? <span style={{cursor: 'pointer', textDecoration: 'none', color: '#3f8b24'}} onClick={() => setScene('sign up')}>Sign up</span></Typography>
                        <TextField
                            fullWidth
                            disabled={loading}
                            type="email"
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            placeholder="email..."
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            disabled={loading}
                            type="password"
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            placeholder="password..."
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />
                        <Button disabled={loading} onClick={() => authenticateUser()} fullWidth variant="contained" style={{backgroundColor: '#3f8b24', color: '#fff', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>log in</Button>
                        <Button disabled={loading} onClick={() => setScene('index')} fullWidth variant="contained" style={{backgroundColor: '#ccc', color: '#222', borderRadius: '25px', textTransform: 'none', marginBottom: '1rem'}}>go back</Button>
                        <Button disabled={loading} onClick={() => setScene('forgot password')} fullWidth variant="outlined" style={{border: 0, color: '#222', marginBottom: '3rem', borderRadius: '25px', textTransform: 'none'}}>forgot password?</Button>
                        <Typography variant="caption" style={{marginTop: '1.5rem', paddingBottom: '1rem', color: '#222'}}>
                            By logging In or registering for this website you agree to our <a style={{color: '#3f8b24'}} href="/legal">Privacy & Terms</a> policies
                        </Typography>
                    </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );

}

export default withStyles(GlobalStyle)(LogIn);
import React, { Fragment, useState } from 'react';

import LandingPage from './views/LandingPage';
import LogIn from './views/LogIn';
import SignUp from './views/SignUp';
import ForgotPassword from './views/ForgotPassword';
import Dashboard from './views/Dashboard/Index';
import Link from './views/Link';
import Legal from './views/Legal';

import {
  SnackbarContent
} from '@material-ui/core';

import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
import '@firebase/storage';
import { CSVDownload } from "react-csv";
const firebaseConfig = {
  apiKey: "AIzaSyB01QtU53_zuYT9PyaGOV2OJYP4OraxVfQ",
  authDomain: "champs-change-app.firebaseapp.com",
  databaseURL: "https://champs-change-app.firebaseio.com",
  projectId: "champs-change-app",
  storageBucket: "champs-change-app.appspot.com",
  messagingSenderId: "204696418799",
  appId: "1:204696418799:web:9b4df8702b83ee1cb81e1a",
  measurementId: "G-MR5FJK4K9Q"
};

firebase.initializeApp(firebaseConfig);

const AUTH = firebase.auth();
const DB = firebase.firestore();
const CDN = firebase.storage();


function App() {

  const currentUrl = new URL(window.location);
  let initialView = 'index';

  switch (currentUrl.pathname) {
    case '/':
      initialView = 'index';
      break;
    case '/dashboard':
      initialView = 'dashboard';
      break;
    case '/login':
      initialView = 'log in';
      break;
    case '/signup':
      initialView = 'sign up';
      break;
    case '/forgotpassword':
      initialView = 'forgot password';
      break;
    case '/legal':
      initialView = 'legal';
      break;
    case '/link':
      initialView = 'link';
      break;
    default:
      // ADD 404
      break;
  }


  const [scene, setScene] = useState(initialView);
  const [message, setMessage] = useState(null);

  const navigate = (path) => {
    switch (path) {
      case 'index':
        window.history.replaceState(null, '', '/');
        setScene('index');
        break;
      case 'log in':
        window.history.replaceState(null, '', '/login');
        setScene('log in');
        break;
      case 'sign up':
        window.history.replaceState(null, '', '/signup');
        setScene('sign up');
        break;
      case 'forgot password':
        window.history.replaceState(null, '', '/forgotpassword');
        setScene('forgot password');
        break;
      case 'dashboard':
        window.history.replaceState(null, '', '/dashboard');
        setScene('dashboard');
        break;
      case 'legal':
        window.history.replaceState(null, '', '/legal');
        setScene('legal');
        break;
      case 'link':
        window.history.replaceState(null, '', '/link');
        setScene('link');
        break;
      default:
        break;
    }
  }
  
  return (
  
    <Fragment>
      {message && <SnackbarContent
        style={{zIndex: 99999999999}}
        className={message.style}
        message={message.content}
      />}
      {scene === 'index' && <LandingPage setScene={navigate} AUTH={AUTH}/>}
      {scene === 'log in' && <LogIn setScene={navigate} AUTH={AUTH} DB={DB} setMessage={setMessage}/>}
      {scene === 'sign up' && <SignUp setScene={navigate} AUTH={AUTH} DB={DB} setMessage={setMessage}/>}
      {scene === 'forgot password' && <ForgotPassword setScene={navigate} AUTH={AUTH} setMessage={setMessage}/>}
      {scene === 'dashboard' && <Dashboard setScene={navigate} firestore={firebase.firestore} AUTH={AUTH} DB={DB} CDN={CDN} setMessage={setMessage}/>}
      {scene === 'link' && <Link DB={DB}/>}
      {scene === 'legal' && <Legal/>}
    </Fragment>
  );
}

export default App;

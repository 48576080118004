import { useState, useEffect } from 'react';
import GlobalStyle from '../../style/Global';
import {
    withStyles,
    Grid,
    Typography,
    IconButton
} from '@material-ui/core';
import moment from 'moment';

import {
    DataGrid
} from '@material-ui/data-grid';

import BackIcon from '@material-ui/icons/KeyboardBackspace';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DownloadIcon from '@material-ui/icons/GetApp';

import { CSVDownload } from "react-csv";

function Contributions ({
    DB,
    organizationId,
    setDashboardScene
}) {
    const [loading, setLoading] = useState(true);
    const [contributions, setContributions] = useState([]);
    const [contributionsForExport, setContributionsForExport] = useState([]);
    const [csvLinkVisible, setCSVLinkVisible] = useState(false);

    const columns = [
        {field: 'donorName', headerName: 'Donor', width: 300},
        {field: 'donorEmail', headerName: 'Email', width: 325},
        {field: 'amount', headerName: 'Contribution', width: 200},
        {field: 'date', headerName: 'Date', width: 250},
        {field: 'institutionName', headerName: 'Bank', width: 300},
        {field: 'accountMask', headerName: 'Account Number (Last 4)', width: 200},
    ]

    useEffect(() => {
        DB.collection('contributions').where('organizationId', '==', organizationId).get()
        .then((contributionsSnapshot) => {
            const contributionsData = [];

            const contributionsForExportData = [['DONOR NAME', 'DONOR EMAIL', 'AMOUNT', 'DATE', 'BANK', 'ACCOUNT NUMBER (Last 4)']];

            contributionsSnapshot.forEach((contributionDoc) => {
                let contribution = contributionDoc.data();
                contribution.id = contributionDoc.id;
                contribution.amount = `$${parseFloat(contribution.amount/100).toFixed(2)}`;
                contribution.date = `${moment().month(contribution.month).format('MMM')} ${moment().date(contribution.date).format('Do')}, ${contribution.year}`;
                contributionsData.push(contribution);
                contributionsForExportData.push([
                    contribution.donorName,
                    contribution.donorEmail,
                    contribution.amount,
                    contribution.date,
                    contribution.institutionName,
                    contribution.accountMask,
                ])
            })

            setContributions(contributionsData);
            setContributionsForExport(contributionsForExportData);
            setLoading(false);
        })
    }, []);

    return (<Grid item container xs={12}>
        <Grid item container xs={12} style={{minHeight: '3rem', backgroundColor: '#444', textAlign: 'center'}}>
            <Grid item xs={12} style={{position: 'relative', margin: '1rem', textAlign: 'left'}}>
                <Typography variant="h6" component="h1" style={{fontWeight: '300', fontSize: '2rem', color: '#f5f5f5'}}>Contributions</Typography>
                <Typography onClick={() => setDashboardScene('index')} variant="h6" component="h1" style={{fontFamily: 'helvetica', fontSize: '1rem', cursor: 'pointer', fontWeight: '200', color: '#f5f5f5'}}><BackIcon style={{verticalAlign: 'bottom'}}/> go back</Typography>

                <IconButton onClick={() => {
                        setCSVLinkVisible(true);
                        setTimeout(() => {setCSVLinkVisible(false)}, 1000);
                    }} aria-label="add" style={{position: 'absolute', top: '25%', right: 0}} size="large"
                >
                    <DownloadIcon style={{color: '#f5f5f5'}}/>
                </IconButton>
                {csvLinkVisible && <CSVDownload data={contributionsForExport} target="_blank" />}
            </Grid>
        </Grid>
        <Grid item xs={12} style={{color: '#222'}}>
            <DataGrid autoHeight disableMultipleSelection onRowClick={(param) => console.log(param)} loading={loading} rows={contributions} columns={columns} pageSize={25}/>
        </Grid>
    </Grid>)
}

export default withStyles(GlobalStyle)(Contributions);